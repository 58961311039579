import { AppBar, Box, Button, Grid, Toolbar, useTheme } from '@mui/material';

import { FeedTwoTone } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../shared/scroll-to-top';

const ButtonStyled = styled(Button)(() => ({
  fontSize: '10px',
  '@media (min-width: 300px)': {
    fontSize: '12px',
  },
  '@media (min-width: 390px)': {
    fontSize: '14px',
  },
  '@media (min-width: 600px)': {
    fontSize: '16px',
  },
  '@media (min-width: 800px)': {
    fontSize: '20px',
  },
})) as typeof Button;

const NavBar = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.default;

  const buttonStyle = {
    color: theme.palette.text.secondary,
    marginRight: '5px',
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: { backgroundColor }, boxShadow: 'none' }}
      >
        <Toolbar>
          <Grid container justifyContent="right" alignItems="center">
            <Grid item>
              <ButtonStyled
                onClick={scrollToTop}
                component={Link}
                to="/patterns"
                color="secondary"
                size="small"
                style={buttonStyle}
              >
                Patterns
              </ButtonStyled>
              <ButtonStyled
                href="https://blog.serverlessadvocate.com/"
                target="_blank"
                color="secondary"
                size="small"
                style={buttonStyle}
              >
                Blog
              </ButtonStyled>
              <ButtonStyled
                onClick={scrollToTop}
                component={Link}
                size="small"
                target="_blank"
                to="https://cloud-cards.com/"
                color="primary"
                style={buttonStyle}
              >
                CloudCards
              </ButtonStyled>

              <ButtonStyled
                onClick={scrollToTop}
                variant="outlined"
                component={Link}
                size="small"
                to="https://serverlessadvocate.substack.com/"
                color="primary"
                startIcon={<FeedTwoTone />}
                target="_blank"
              >
                Newsletter
              </ButtonStyled>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
