import { AppBar, Box, Grid, Link, Toolbar, Typography } from '@mui/material';

import { CampaignTwoTone } from '@mui/icons-material';

const NewsBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        variant="outlined"
        sx={{
          backgroundColor: '#c1e9e1',
          animation: 'colorTransition 1.5s infinite alternate',
          '@keyframes colorTransition': {
            from: { backgroundColor: '#d6f3ed' },
            to: { backgroundColor: '#d3bbf9' },
          },
        }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CampaignTwoTone fontSize="large" color="primary" />
                <Typography variant="body2" fontSize="medium" color="primary">
                  Check out{' '}
                  <Link
                    href="https://cloud-cards.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: 'none', fontWeight: 'bold' }}
                    color="primary"
                  >
                    CloudCards
                  </Link>{' '}
                  for free learning!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NewsBar;
